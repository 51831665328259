import pino from 'pino';

export const logger = {
  init: initLogger.bind(this),
  trace: (...args: any[]) => {
    console.log('[trace]', args?.join(' '));
  },
  debug: (...args: any[]) => {
    console.log('[debug]', args?.join(' '));
  },
  info: (...args: any[]) => {
    console.log('[info]', args?.join(' '));
  },
  warn: (...args: any[]) => {
    console.log('[warn]', args?.join(' '));
  },
  error: (...args: any[]) => {
    console.log('[error]', args?.join(' '));
  },
  fatal: (...args: any[]) => {
    console.log('[fatal]', args?.join(' '));
  }
};

function initLogger(logLevel: string) {
  logLevel = getLogLevel(logLevel);
  console.log('Initializing logger with log level: ' + logLevel); // eslint-disable-line no-console
  const pinoLogger = pino({ level: logLevel });

  logger.trace = pinoLogger.trace.bind(pinoLogger);
  logger.debug = pinoLogger.debug.bind(pinoLogger);
  logger.info = pinoLogger.info.bind(pinoLogger);
  logger.warn = pinoLogger.warn.bind(pinoLogger);
  logger.error = pinoLogger.error.bind(pinoLogger);
  logger.fatal = pinoLogger.fatal.bind(pinoLogger);

  if (typeof (logger as any)[logLevel] === 'function') {
    (logger as any)[logLevel]('Logger initialized with log level: ' + logLevel);
  }
}

function getLogLevel(logLevel: string) {
  switch (logLevel) {
    case 'trace':
    case 'debug':
    case 'info':
    case 'warn':
    case 'error':
    case 'fatal':
      return logLevel;
    default:
      return 'info';
  }
}
